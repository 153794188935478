import { WS_ENDPOINT_URL } from "configs/AppConfig";
import { ACCESS_TOKEN } from "constants/AuthConstant";
import { CONNECT, DISCONNECT, NEW_MESSAGE } from "../constants/Websocket";
import {
  connectSuccess,
  disconnectSuccess,
  newMessage,
} from "../actions/Websocket";
import {
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  putResolve,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { END, eventChannel } from "redux-saga";
import AuthService from "services/auth/AuthService";

// Function to get reducer state in sagas
export const getState = (state) => state.websocket;
export const getUserState = (state) => state.user;

export function* socketNewMessage() {
  yield takeEvery(NEW_MESSAGE, function* ({ payload }) {
    try {
      // Parse new message
      const parsedData = JSON.parse(payload);
      const command = parsedData.command;

      // Get the store
      const store = yield select(getState);

      // Get the callbacks dictionary
      let callbacks = store.callbacks;
      if (Object.keys(callbacks).length === 0) {
        return;
      }

      // Live update currently for capability or operation or batch in asset overview component.

      if (
        command === "capabilityUpdate" ||
        command === "operationUpdate" ||
        command === "batchUpdate"
      ) {
        const userStore = yield select(getUserState);
        let assets = userStore.assets;

        // Call the callback function
        callbacks[command](assets, parsedData.data);
      }
    } catch (err) {
      console.log("ERROR", err);
    }
  });
}

// Use this to actually throw exceptions, allows for easier debugging.
const dispatch = putResolve;

function createWebSocketConnection() {
  return new Promise((resolve, reject) => {
    const path = `${WS_ENDPOINT_URL}websocket/live-update/?source=front-end&token=${localStorage.getItem(
      ACCESS_TOKEN
    )}`;
    const socket = new WebSocket(path, []);

    socket.onopen = function () {
      resolve(socket);
    };

    socket.onerror = function (evt) {
      reject(evt);
    };
  });
}

function createSocketChannel(socket) {
  return eventChannel((emit) => {
    socket.onmessage = (event) => {
      emit(event.data);
    };

    socket.onclose = () => {
      emit(END);
    };

    const unsubscribe = () => {
      socket.onmessage = null;
    };

    return unsubscribe;
  });
}

function* listenForSocketMessages() {
  let socket;
  let socketChannel;

  try {
    socket = yield call(createWebSocketConnection);
    socketChannel = yield call(createSocketChannel, socket);

    // tell the application that we have a connection
    yield dispatch(connectSuccess(socket));

    while (true) {
      // wait for a message from the channel
      const payload = yield take(socketChannel);

      // a message has been received, dispatch an action with the message payload
      yield dispatch(newMessage(payload));

      yield delay(10000);
    }
  } catch (error) {
    //yield dispatch(LiveDataActions.connectionError('Error while connecting to the WebSocket'));
    console.log(error);
    console.log("Error while connecting to the WebSocket");
  } finally {
    if (yield cancelled()) {
      console.log("Connection was terminated from the other side");
      // close the channel
      if (socketChannel && socket) {
        socketChannel.close();
        // close the WebSocket connection
        socket.close();
      }
    } else {
      //yield dispatch(LiveDataActions.connectionError('WebSocket disconnected'));
      console.log("WebSocket disconnected");
      console.log("Trying to reconnect");

      yield delay(5000);

      try {
        console.log("Refreshing token");
        const rs = yield call(AuthService.refreshToken);
        const accessToken = rs.access;
        localStorage.setItem(ACCESS_TOKEN, accessToken);
      } catch (_error) {
        console.log(_error);
      }

      yield call(listenForSocketMessages);
    }
  }
}

export function* wsConnect() {
  yield takeEvery(CONNECT, function* () {
    // starts the task in the background
    const socketTask = yield fork(listenForSocketMessages);

    // when DISCONNECT action is dispatched, we cancel the socket task
    yield take(DISCONNECT);
    yield cancel(socketTask);
    yield dispatch(disconnectSuccess());
  });
}

export default function* rootSaga() {
  yield all([fork(wsConnect), fork(socketNewMessage)]);
}
