import { combineReducers } from "redux";
import auth from "./slices/authSlice";
import theme from "./slices/themeSlice";
import User from "redux/reducers/User";
import websocket from "redux/reducers/Websocket";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    user: User,
    theme,
    websocket,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
